<template>
  <div class="layout">
    <Header :showSwitchLang="true"/>
    <Banner @btnClick="showDialog" />
    <div
      class="toggle-tab"
      ref="tabWrapper"
    >
      <div class="view-container">
        <ToggleTab ref="toggleTab" />
      </div>
    </div>
    <div
      class="swiper-box"
      v-if="showSwiper && swiperList.length"
    >
      <div class="swiper-container swiper-no-swiping swiper-banner">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="item in swiperList"
            :key="item.id"
            :data-item="item.bannerNum"
          >
            <img
              :src="isMobile() ? item.coverMoveUrl : item.coverPcUrl"
              @click="handleGoDetails(item)"
            />
          </div>
        </div>
      </div>
    </div>
    <router-view :banner="swiperList"/>
    <div class="open-card" :class="{en: lang == 'en'}">
      <div class="view-container">
        <OpenCard @btnClick="showDialog" />
      </div>
    </div>
    <Footer />
    <FormDialog
      :visible.sync="showForm"
      :pageType="'4'"
    />
  </div>
</template>

<script>
import { getRect, getScrollTop } from '@/utils/viewport'
import { isMobile } from '@/utils/utils'
import { mapGetters, mapMutations } from 'vuex'
import api from '@/api/index'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import mixins from '@/mixins/layout.mixins'

export default {
  mixins: [mixins],
  data () {
    return {
      showForm: false,
      showSwiper: false,
      isMobile,
      swiper: null,
      swiperList: []
    }
  },
  computed: {
    ...mapGetters(['lang'])
  },
  created () {
    this.setLanguage()
  },
  mounted () {
    // 滚动吸顶
    const tabWrapper = this.$refs.tabWrapper
    const { top } = getRect(tabWrapper)
    document.addEventListener('scroll', () => {
      const scrollTop = getScrollTop()
      const containsScroll = tabWrapper.classList.contains('scroll')
      if (scrollTop >= top) {
        if (!containsScroll) {
          tabWrapper.classList.add('scroll')
          // 重新计算tab偏移
          this.$refs.toggleTab.setSlider()
        }
      } else {
        if (containsScroll) {
          tabWrapper.classList.remove('scroll')
          // 重新计算tab偏移
          this.$refs.toggleTab.setSlider()
        }
      }
    })

    this.getBannerList()
  },
  watch: {
    $route: {
      immediate: true,
      handler (newV) {
        console.log(newV)
        // pc端全部展示，移动端只有首页有
        if (isMobile()) {
          if (newV.name === 'Home') {
            this.showSwiper = true
            if (!this.swiper) {
              this.$nextTick(() => {
                this.initSwiper()
              })
            }
          } else {
            this.showSwiper = false
            this.swiper = null
          }
        } else {
          this.showSwiper = true
          if (!this.swiper) {
            this.$nextTick(() => {
              this.initSwiper()
            })
          }
        }
      }
    },
    lang () {
      this.getBannerList()
    }
  },
  methods: {
    ...mapMutations('app', ['SET_ALL']),

    initSwiper () {
      const _this = this
      this.swiper = new Swiper('.swiper-banner', {
        autoplay: {
          dalay: 3000,
          disableOnInteraction: true,
          preventClicksPropagation: false,
          observer: true, // 修改swiper自己或子元素时，自动初始化swiper
          observeParents: true // 修改swiper的父元素时，自动初始化swiper
        },
        // loop: true,
        on: {
          onClick: function (swiper) {
            const item = swiper.clickedSlide.attributes['data-item'].nodeValue// 转换为对象
            console.log(swiper, item)
            _this.handleGoDetails(item)
          }
        }
      })
    },

    getBannerList () {
      this.swiper = null
      this.swiperList = []
      api.getBannerList().then(res => {
        this.swiperList = res.map(item => item)
        if (res.length) {
          this.$nextTick(() => {
            this.initSwiper()
          })
        }
      })
    },
    handleGoDetails (item) {
      this.$router.push({
        name: 'ActiveDetail',
        query: {
          ...this.$route.query,
          bannerNum: item.bannerNum
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.layout {
  position: relative;
  .toggle-tab {
    position: static;
    width: 100%;
    margin-top: -39px;
    margin-bottom: -39px;
    transition: position 0.5s ease;
    &.scroll {
      position: fixed;
      top: 0;
      z-index: 2000;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .open-card {
    margin-top: -133px;
    margin-bottom: -90px;
    &.en {
      margin-top: -155px;
    }
  }
  .swiper-box {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    .swiper-container {
      position: absolute;
      top: -560px;
      right: 30px;
      width: 380px;
      height: 475px;
      flex-shrink: 0;
      margin-left: 100px;
      border-radius: 5px;

      .swiper-wrapper {
        .swiper-slide {
          text-align: center;
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            display: block;
            width: 100%;
            height: 100%;
            // object-fit: cover;
            user-select: none;
            -webkit-user-drag: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: @mobileWidth) {
  .layout {
    .toggle-tab {
      display: none;
    }
    .open-card {
      margin-top: -44px;
      margin-bottom: -40px;
      &.en {
        margin-top: -64px;
        margin-bottom: -48px;
      }
    }
    .swiper-box {
      .swiper-container {
        position: absolute;
        top: 18px;
        right: 50%;
        transform: translateX(50%);
        width: 335px;
        height: 100px;
        flex-shrink: 0;
        border-radius: 10px;

        .swiper-wrapper {
          .swiper-slide {
            text-align: center;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              display: block;
              width: 100%;
              height: 100%;
              // object-fit: cover;
            }
          }
        }
      }
    }
  }
}
</style>
